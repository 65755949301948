<template>
  <div id="events">
    <b-container>
      <b-row>
        <b-col cols="7">
          <b-form @submit.prevent="onSubmit">

            <b-form-group
              label="Bild" 
              label-for="form-image"
              description="Auflösung: 999px x 999px"
             >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="image-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-file           
                  type="file"
                  placeholder="Bild auswählen"
                  drop-placeholder=""
                  ref="Image"
                  @change="uploadImage"
                  accept="image/*" 
                ></b-form-file>
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="name"
              label="Eventname:"
              label-for="Name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                type="text"
                placeholder="Eventname"
                required
              ></b-form-input>
            </b-form-group>

            <label class="mt-3">Kurztext</label>
            <b-form-textarea
              id="shortText"
              v-model="form.shortText"
              placeholder="Schreibe eine kurze zusammenfassung"
              rows="2"
              max-rows="6"
            ></b-form-textarea>

            <label class="mt-3">Langtext</label>
            <b-form-textarea
              id="longText"
              v-model="form.longText"
              placeholder="Schreibe eine ausführliche zusammenfassung"
              rows="4"
              max-rows="6"
            ></b-form-textarea>

            <b-form-group
              label="Raumplan" 
              label-for="form-room-plan"
              description="Auflösung: 999px x 999px"
              class="mt-3"
             >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="image-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-file           
                  type="file"
                  placeholder="Bild auswählen"
                  drop-placeholder=""
                  ref="room-plan"
                  @change="uploadRoomPlan"
                  accept="image/*" 
                ></b-form-file>
              </b-input-group>
            </b-form-group>

            <img v-if="form.roomPlan" :src="form.roomPlan" style="max-width: 635px;"/>

            <b-form-group
              id="location"
              label="Eventlocation:"
              label-for="location"
              description="Muss über in der Apple Maps und Google Maps Suche geprüft werden!"
            >
              <b-form-input
                id="location"
                v-model="form.location"
                type="text"
                placeholder="Eventlocation"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox class="mt-3" switch v-model="justOneDay">Eintägig</b-form-checkbox>

            <div v-if="!justOneDay"> 
              <label class="mt-3" for="from">Startdatum</label>
              <b-form-datepicker id="from" v-model="fromRaw" locale="de"></b-form-datepicker>

              <label class="mt-3" for="to">Enddatum</label>
            </div>
            <label class="mt-3" v-else for="to">Datum</label>
            <b-form-datepicker id="to" v-model="toRaw"></b-form-datepicker>

            <b-form-checkbox class="mt-3" switch v-model="form.agendaPrivate">Agenda ist Privat</b-form-checkbox>

            <label class="mt-3">Agenda</label>
             <div v-for="(agenda, agendaIndex) in form.agenda" :key="agendaIndex">
              <div class="d-flex justify-content-between">
                <b-form-group class="w-50" v-if="!justOneDay">
                  <b-form-input
                    v-model="agenda.name"
                    type="text"
                    placeholder="Tag"
                    required
                  ></b-form-input>
                </b-form-group>
                <font-awesome-icon @click="deleteAgendaDay(agendaIndex)" :icon="['fas', 'trash']" />
              </div>
              <b-list-group>
                <b-list-group-item v-for="(row, index) in agenda.data" :key="index">
                  <div class="d-flex justify-content-between">
                    <b-form-group class="w-50">
                      <b-form-input
                        v-model="row.time"
                        type="text"
                        placeholder="Uhrzeit"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <font-awesome-icon @click="deleteAgendaRow(agendaIndex, index)" :icon="['fas', 'trash']" />
                  </div>
                  <b-form-group>
                      <b-form-input
                        v-model="row.title"
                        type="text"
                        placeholder="Titel"
                        required
                      ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                      <b-form-input
                        v-model="row.content"
                        type="text"
                        placeholder="Beschreibung"
                      ></b-form-input>
                  </b-form-group>
                </b-list-group-item>
              </b-list-group>
              <div class="mt-3 mb-3">
              <b-btn variant="outline-primary" @click="addAgendaRow(agendaIndex)" v-if="form.agenda">Weiteren Agenda Einrag</b-btn>
              </div>
            </div>
            <div class="mt-3">
              <b-btn variant="outline-primary" @click="addAgendaDay()">Weiteren Agenda Tag hinzufügen</b-btn>
            </div>
            <b-button type="submit" class="mt-5" variant="primary">Speichern</b-button>
          </b-form>
        </b-col>
        <b-col cols="5">
          <h3>Vorschau Home</h3>
          <Card :data="form" id=""/>
          <h3 class="mt-5">Vorschau Eventansicht</h3>
          <Event :event="form" style="border: 1px #e0e0e0 solid;"/>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Card from './appElements/Card'
import Event from './appElements/Event'
import { storage, firestore } from "../firebase"
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";

export default {
  name: 'Events',
  components:{
    Card,
    Event
  },
  computed: {
    ...mapGetters({
      user: "user",
      events: "events"
    }),
  },
  watch: {
    toRaw: function (toRaw) {
      toRaw ? this.form.to = new Date(toRaw) : this.form.to = new Date()
    },
    fromRaw: function (fromRaw) {
      fromRaw ? this.form.from = new Date(fromRaw) : this.form.from = undefined
    },
    justOneDay: function(justOneDay){
      justOneDay ? (this.form.from = undefined) : (this.form.agenda[0].name = "")
      this.form.justOneDay = justOneDay
    }

  },
  data(){
    return {
      form:{
        name: null,
        shortText: null,
        longText: null,
        to: new Date(),
        from: undefined,
        image: null,
        roomPlan: null,
        location: null,
        agendaPrivate: false,
        agenda: [
          {
          name: '',
          data:[
            {
              time: "",
              title: "",
              content: ""
            }
          ]
         }
        ]
      },
      toRaw: new Date(),
      fromRaw: new Date(),
      justOneDay: false
    }
  },
  methods:{
    onSubmit(){
      let form = this.form
      if (form.name && form.shortText && form.to && form.image){
        let data = {
          name: this.form.name,
          justOneDay: this.justOneDay,
          shortText: this.form.shortText,
          longText: this.form.longText,
          to: Timestamp(new Date(this.form.to)),
          image: this.form.image,
          agendaPrivate: this.form.agendaPrivate,
          agenda: this.form.agenda,
          roomPlan: this.form.roomPlan,
          location: this.form.location
        }
        !this.justOneDay && (data.from = Timestamp(new Date(this.form.from)))
        this.form.agenda.length <= 0 && delete data.agenda
        
        addDoc(collection(firestore, 'events'),data)
        .then(()=>{
        this.$store.dispatch('fetchEvents')
        this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          console.error(error);
        });
      } else {
        this.error = 'Bitte fülle mehr Felder aus.'
      }
    },
    uploadImage(event){
      const uploadTask = uploadBytesResumable(ref(storage, 'events/'+Date.now()), event.target.files[0]);

      uploadTask.on('state_changed', (snapshot) => {
        console.warn(snapshot)
      }, error=>{console.log(error.message)},
      ()=>{
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.form.image = downloadURL;
          });
        }      
      );
    },
    uploadRoomPlan(event){
           const uploadTask = uploadBytesResumable(ref(storage, 'events/'+Date.now()), event.target.files[0]);

      uploadTask.on('state_changed', (snapshot) => {
        console.warn(snapshot)
      }, error=>{console.log(error.message)},
      ()=>{
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.form.roomPlan = downloadURL;
          });
        }      
      );
    },
    addAgendaRow(index){
      this.form.agenda[index].data.push({
        time: "",
        title: "",
        content: ""
      })
    },
    addAgendaDay(){
 this.form.agenda ? this.form.agenda.push({
        name: "",
        data: [{
          time: "",
          title: "",
          content: ""
        }]
      }) : this.form.agenda =[ {
        name: "",
        data: [{
          time: "",
          title: "",
          content: ""
        }]
      }]
    },
    deleteAgendaRow(agendaIndex, index){
      this.form.agenda[agendaIndex].data.splice(index, 1); 
    },
    deleteAgendaDay(agendaIndex,){
      this.form.agenda.splice(agendaIndex, 1); 
    }
  },
  mounted(){
    this.$bvModal.show('add-event')
  }
}
</script>

<style scoped>
 
</style>
