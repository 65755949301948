<template>
  <div id="events">
    <b-container class="mt-3 mb-3">
      <b-row>
        <b-col> 
          <div class="sticky-top">
            <h4>Teilnehmer</h4>
            <b-list-group>
              <b-list-group-item class="clickable"  v-for="(user, index) in users" :key="index" @click="selectedUser = index" :class="selectedUser === index && 'selected'">
                <div>{{user.name}}<br>{{user.company}}<br>{{user.email}}</div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
        <b-col class="sticky-top ownScroll">
          <div >
            <h4>Termine</h4>
            <b-list-group v-if="meetings">
              <b-list-group-item class="clickable" v-for="(time, index) in times" :key="time.name + index" :class="isInTime(time.name, users[selectedUser]).length == 1 && 'selected' || isInTime(time.name, users[selectedUser]).length > 1 && 'selectedRed'">{{time.name}}<br>
                <span  v-for="(room, index) in isInTime(time.name, users[selectedUser])" :key="index">{{room.room}} - {{room.titel}}<br></span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MeetingCheck',
  computed: {
    ...mapGetters({
      users: "users",
      meetings: "meetings"
    }),
  },
  data(){
    return{
      selectedUser: 0,
      times:[
        {showtime: '10:00 - 16:00', name: 'Mi. 10:00 - 16:00', time: new Date(Date.UTC(2022, 8, 28, 8, 0, 0)) },
        {showtime: '13:00 - 14:00', name: 'Mi. 13:00 - 14:00', time: new Date(Date.UTC(2022, 8, 28, 11, 0, 0)) },
        {showtime: 'ab 16:00', name: 'Mi. ab 16:00', time: new Date(Date.UTC(2022, 8, 28, 14, 0, 0)) },
        {showtime: '18:00 - 18:30', name: 'Mi. 18:00 - 18:30', time: new Date(Date.UTC(2022, 8, 28, 16, 0, 0)) },
        {showtime: '18:30 - 22:00', name: 'Mi. 18:30 - 22:00', time: new Date(Date.UTC(2022, 8, 28, 16, 30, 0)) },
        {showtime: '18:30 - 0:00',  name: 'Mi. 18:30 - 0:00', time: new Date(Date.UTC(2022, 8, 28, 16, 30, 1)) },
        {showtime: '7:00 - 10:00',  name: 'Do. 7:00 - 10:00', time: new Date(Date.UTC(2022, 8, 29, 5, 0, 0)) }, 
        {showtime: '10:00 - 10:25', name: 'Do. 10:00 - 10:25', time: new Date(Date.UTC(2022, 8, 29, 8, 0, 0)) }, 
        {showtime: '10:30 - 10:55', name: 'Do. 10:30 - 10:55', time: new Date(Date.UTC(2022, 8, 29, 8, 30, 0)) },
        {showtime: '11:00 - 11:25', name: 'Do. 11:00 - 11:25', time: new Date(Date.UTC(2022, 8, 29, 9, 0, 0)) },
        {showtime: '11:30 - 11:55', name: 'Do. 11:30 - 11:55', time: new Date(Date.UTC(2022, 8, 29, 9, 30, 0)) },
        {showtime: '12:00 - 13:30', name: 'Do. 12:00 - 13:30', time: new Date(Date.UTC(2022, 8, 29, 10, 0, 0)) },
        {showtime: '13:30 - 14:00', name: 'Do. 13:30 - 14:00', time: new Date(Date.UTC(2022, 8, 29, 11, 30, 0)) },
        {showtime: '14:00 - 14:30', name: 'Do. 14:00 - 14:30', time: new Date(Date.UTC(2022, 8, 29, 12, 0, 0)) },
        {showtime: '14:30 - 14:45', name: 'Do. 14:30 - 14:45', time: new Date(Date.UTC(2022, 8, 29, 12, 30, 0)) },
        {showtime: '14:45 - 15:10', name: 'Do. 14:45 - 15:10', time: new Date(Date.UTC(2022, 8, 29, 12, 45, 0)) },
        {showtime: '15:15 - 15:40', name: 'Do. 15:15 - 15:40', time: new Date(Date.UTC(2022, 8, 29, 13, 15, 0)) },
        {showtime: '15:45 - 16:10', name: 'Do. 15:45 - 16:10', time: new Date(Date.UTC(2022, 8, 29, 13, 45, 0)) },
        {showtime: '16:15 - 16:40', name: 'Do. 16:15 - 16:40', time: new Date(Date.UTC(2022, 8, 29, 14, 15, 0)) },
        {showtime: '16:45 - 17:10', name: 'Do. 16:45 - 17:10', time: new Date(Date.UTC(2022, 8, 29, 14, 45, 0)) },
        {showtime: '17:15 - 17:40', name: 'Do. 17:15 - 17:40', time: new Date(Date.UTC(2022, 8, 29, 15, 15, 0)) },
        {showtime: '17:45 - 18:10', name: 'Do. 17:45 - 18:10', time: new Date(Date.UTC(2022, 8, 29, 15, 45, 0)) },
        {showtime: '18:15 - 18:40', name: 'Do. 18:15 - 18:40', time: new Date(Date.UTC(2022, 8, 29, 16, 15, 0)) },
        {showtime: '19:00 - 19:15', name: 'Do. 19:00 - 19:15', time: new Date(Date.UTC(2022, 8, 29, 17, 0, 0)) },
        {showtime: '19:30 - 21:30', name: 'Do. 19:30 - 21:30', time: new Date(Date.UTC(2022, 8, 29, 17, 30, 0)) },
        {showtime: '21:30 - 00:00', name: 'Do. 21:30 - 00:00', time: new Date(Date.UTC(2022, 8, 29, 19, 30, 0)) },
        {showtime: '7:00 - 9:00', name: 'Fr. 7:00 - 9:00', time: new Date(Date.UTC(2022, 8, 30, 5, 0, 0)) },
        {showtime: '9:00 - 9:45', name: 'Fr. 9:00 - 9:45', time: new Date(Date.UTC(2022, 8, 30, 7, 0, 0)) },
        {showtime: '10:00 - 10:45', name: 'Fr. 10:00 - 10:45', time: new Date(Date.UTC(2022, 8, 30, 8, 0, 0)) },
        {showtime: 'bis 11:00', name: 'Fr. bis 11:00', time: new Date(Date.UTC(2022, 8, 30, 9, 0, 0)) },
        {showtime: 'ab 11:00', name: 'Fr. ab 11:00', time: new Date(Date.UTC(2022, 8, 30, 9, 0, 1)) },
        {showtime: '20:00 - 21:30', name: 'Mi. 20:00 - 21:30', time: new Date(Date.UTC(2022, 8, 28, 18, 0, 0)) },
        {showtime: '21:30 - 0:00',  name: 'Mi. 21:30 - 0:00', time: new Date(Date.UTC(2022, 8, 28, 19, 30, 0)) },
        {showtime: '21:00 - 22:00', name: 'Mi. 21:00 - 22:00', time: new Date(Date.UTC(2022, 8, 28, 19, 0, 0)) },
      ],
    }
  },
  methods:{
    isInTime(timeName, user){
      let thistime =  Object.values(this.meetings).filter(meeting => (meeting.timeString === timeName && meeting.members.includes(user.email)))

      if (thistime.length > 0){
        return thistime
      } else {
        return []
      }
    }
  }
  // mounted(){
  //   console.log(Object.values(this.meetings))
  // }
}
</script>

<style scoped>
  .selected, .selected:hover {
    background-color: #4089C9;
    color: white;
  }
  .selectedRed {
    background-color: #cb1515;
    color: white;
  }
  .ownScroll {
    height: calc(100vh - 20px);
    overflow-x: scroll;
  }
</style>
