import Vue from "vue";
import Vuex from "vuex";

import { firestore } from './firebase'
import { collection, getDocs} from "firebase/firestore"

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      admin: false,
      data: null
    },
    users: null,
    events: null,
    meetings: null,
    privateEvents: null
  },
  getters: {
    user(state){
      return state.user
    },
    users(state){
      return state.users
    },
    meetings(state){
      return state.meetings
    },
    events(state){
      return state.events
    },
    privateEvents(state){
      return state.privateEvents
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_ADMIN(state, value) {
      state.user.admin = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_EVENTS(state, data) {
      state.events = data;
    },
    SET_PRIVATE_EVENTS(state, data) {
      state.privateEvents = data;
    },
    SET_USERS(state, data) {
      state.users = data;
    },
    SET_MEETINGS(state, data) {
      state.meetings = data;
    }
  },
  actions: {
    async fetchUser({ commit }, user) {
      let tokens = await user.getIdTokenResult()
      commit("SET_LOGGED_IN", user !== null);
      if (user) {      
        commit("SET_ADMIN", user.admin)
        commit("SET_USER", {
          provider: user.providerData.provider,
          admin: tokens.claims?.admin,
          uid: user.uid,
        });
      } else {
        commit("SET_USER", null);
      }
    },
    async fetchEvents({ commit }) {
      let data = {}
      const querySnapshot = await getDocs(collection(firestore, 'events'))
      querySnapshot.forEach(doc => {
        data[doc.id] = doc.data()
      });
      commit('SET_EVENTS', data)
    },
    async fetchUsers({ commit }) {
      let data = []
      const querySnapshot = await getDocs(collection(firestore, 'users'))
      querySnapshot.forEach(doc => {
          data.push(doc.data())
        });
      commit('SET_USERS', data)
    },
    async fetchMeetings({ commit }) {
      let data = {}
      const querySnapshot = await getDocs(collection(firestore, 'meetings'))
      querySnapshot.forEach(doc => {
        data[doc.id] = doc.data()
      });
      commit('SET_MEETINGS', data)
    }
  }
});