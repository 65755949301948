import Vue from 'vue'
import store from './store'
import router from "./routes";
import App from './App.vue'

import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth';

// Font Awesome
import './fontawesome.js'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

onAuthStateChanged(auth, async user => {
  if(user){
    await store.dispatch('fetchEvents')
    await store.dispatch('fetchUsers')
    await store.dispatch('fetchMeetings')
    await store.dispatch("fetchUser", user)
  }

  new Vue({
    render: h => h(App),
    router,
    store,
    beforeMount() {
    }
  }).$mount('#app')

})
