<template>
  <div class="custom-card">
    <b-card
    :title="data.name"
    :img-src="data.image"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 100%"
    class="m-2">
      <small>
        <span v-if="data.from">{{checkDate(data.from)}} - </span>
        <span>{{checkDate(data.to)}}</span>
      </small>
        <p>{{data.shortText}}</p>
        <div class="arrow-right">
          <div>mehr </div>
          <div class="ml-2"><font-awesome-icon :icon="['fas', 'chevron-right']" /></div>
        </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    data: Object,
    id: String
  },
  data() {
    return {
      dateOptions: { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
    }
  },
  components:{
  },
  methods:{
    checkDate(date){
      if (date instanceof Date && date.getTime()){
        return (date.toLocaleDateString('de-DE', this.dateOptions))
      } else {
        return (date.toDate().toLocaleDateString('de-DE', this.dateOptions))
      }
    }
  }
}
</script>

<style scoped>
.arrow-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.custom-card a {
  color: #5C5C5C !important;
  text-decoration: none !important;
}
</style>
