// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDzYA86X3L0836K1YPibfHOawmDlOga0HA",
  authDomain: "clgmbh-comline-events.firebaseapp.com",
  projectId: "clgmbh-comline-events",
  storageBucket: "clgmbh-comline-events.appspot.com",
  messagingSenderId: "650913239768",
  appId: "1:650913239768:web:00b673c191d005a3057dad"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const storage = getStorage(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase);

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, "127.0.0.1", 8081);
  connectFunctionsEmulator(functions, "127.0.0.1", 5002);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

export { auth, firestore, functions, storage };
