// Vue
import Vue from 'vue'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faEnvelope,
  faKey,
  faSignOut,
  faPlus,
  faTrash,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faUser,
  faBars,
  faBalanceScaleRight,
  faHome,
  faMap,
  faCircle
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faEnvelope,
  faKey,
  faSignOut,
  faPlus,
  faTrash,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faUser,
  faBars,
  faBalanceScaleRight,
  faHome,
  faMap,
  faCircle
)
Vue.component('font-awesome-icon', FontAwesomeIcon)