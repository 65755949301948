<template>
  <div id="show-users">
        <b-list-group class="m-5">
          <b-list-group-item>
            <b-row>
              <b-col cols="2"><b>Name</b></b-col>
              <b-col cols="3"><b>E-Mail</b></b-col>
              <b-col cols="4"><b>Firma</b></b-col>
              <b-col cols="1"><b>Gruppe</b></b-col>
              <b-col cols="1" ></b-col>
              <b-col cols="1" ></b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-for="(user, index) in users" :key="index">
            <b-row>
              <b-col cols="2">{{user.name}}</b-col>
              <b-col cols="3">{{user.email}}</b-col>
              <b-col cols="4">{{user.company}}</b-col>
              <b-col cols="1">{{user.group}}</b-col>
              <b-col cols="1"> <span v-if="registered.includes(user.email.toUpperCase())"><b-badge variant="success">Konto vorhanden</b-badge></span></b-col>
              <b-col cols="1" class="text-right"><font-awesome-icon :icon="['fas', 'trash']" @click="showDeleteModal(user.email)" /></b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
    <b-modal ref="modal" title="Teilnehmer Löschen" @ok="deleteThis()" ok-title="Löschen" cancel-title="Abbrechen" cancel-variant="danger">
      <p class="my-4">Dieser Teilnehmer wird wird bei bestätigung <b>unwiederbringlich</b> gelöscht.</p>
    </b-modal>
  </div>

</template>

<script>
import { mapGetters } from "vuex"
import { doc, deleteDoc } from "firebase/firestore"
import { firestore } from "../firebase"
import store from "../store"

export default {
  name: 'ShowUsers',
  computed: {
    ...mapGetters({
      users: "users",
    }),
  },
  props:{
    registered: Array
  },
  data(){
    return {
      toDelete: ""
    }
  },
  methods:{
    deleteThis(){
      deleteDoc(doc(firestore, 'users', this.toDelete))
      .then(()=>{
        store.dispatch('fetchUsers')
      })
      .catch((err)=>{
        console.log(err)
      })
    },
    showDeleteModal(email){
      this.toDelete = email
      // console.log(email)
      this.$refs['modal'].show()
    },
  },
  mounted(){

  }
}
</script>

<style scoped>
 
</style>
