<template>
  <div id="app">
    <NavbarAdmin v-if="user.data && user.data.admin" />
    <Navbar v-else/>
    <router-view id="routerView"></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavbarAdmin from './components/NavbarAdmin.vue'
import { mapGetters } from "vuex"


export default {
  name: 'App',
  components: {
    Navbar,
    NavbarAdmin
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  mounted(){

  }
}
</script>

<style>
#routerView {
  margin-top: 30px; 
}
</style>
