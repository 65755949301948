<template>
  <div class="agenda">
    <div class="content-area">
      <span id="agenda-title">Agenda</span>
    </div>
    <div v-for="(agenda, agendaIndex) in data" :key="agendaIndex">
      <div class="content-area">
        <span class="agenda-days" v-show="agenda.name !== 'oneDay'">{{agenda.name}}</span>
      </div>
      <b-list-group>
        <b-list-group-item v-for="(row, index) in agenda.data" :key="agendaIndex+index">
          <div class="agenda-row">
            <div class="agenda-time">
              <small>{{row.time}}</small>
            </div>
            <div>
              <div class="agenda-title">{{row.title}}</div>
              <div class="agenda-content">{{row.content}}</div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agenda',
  props:{
    data: Array
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
  .list-group-item {
    padding-left: 0;
    padding-right: 15px;
    background-color: #f8f9fa;
  }
  #agenda-title {
    font-size: 18px;
    font-weight: 700; 
  }
  .agenda-days {
    font-size: 16px;
    font-weight: 400;
  }
  .list-group {
    border-radius: 0;
    margin-bottom: 15px;
  }
  .agenda-title {
    font-weight: 600;
    font-size: 16px;
  }
  .agenda-content {
    font-weight: 400;
    font-size: 14px;
  }
  .agenda-time {
    min-width: 100px;
    max-width: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .agenda-row {
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
  .content-area {
    margin: 15px; 
  }
</style>
