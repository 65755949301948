<template>
  <div id="home">
    <b-container>
      <b-row>
        <b-col><h3>Aktive Events</h3></b-col>
        <!-- <b-col>{{events}}</b-col> -->
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col v-for="(data) in Object.entries(events)" :key="data[0]" cols="4">
          <router-link :to="`/events-edit/${data[0]}`">
            <Card :data="data[1]" :id="data[0]"></Card>
          </router-link>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Card from './appElements/Card'

export default {
  name: 'Home',
  components:{
    Card
  },
  computed: {
    ...mapGetters({
      user: "user",
      events: "events",
      privateEvents: "privateEvents"
    }),
  },
  data(){
    return {
      
    }
  },
  methods:{

  },
  mounted(){

  }

}
</script>

<style scoped>
 a {
  color: #5C5C5C !important;
  text-decoration: none !important;
}
</style>
