<template>
  <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute;">
    <div class="loginBox">
      <div class="d-flex justify-content-center align-items-center">
        <div id="logoBox">
        <Logo :fill="'#000000'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo> <span id="logotext">Events</span>
        </div>
      </div>
      <div v-if="error" class="d-flex alert alert-danger error">{{error}}</div>
      <div v-if="success" class="d-flex alert alert-success success">{{success}}</div>
    </div>
  </div>
</template>

<script>
import Logo from '../assets/Logo'

export default {
  name: 'Login',
  components:{
    Logo
  },
  props:{
    oob: String
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null,
      success: null
    };
  },
  mounted(){
  
  }
};
</script>
<style scoped>
  .loginBox {
    width: 400px; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px;
    border: 1px solid #dedede;
    border-radius: 0.35rem;
    border-top: 4px solid #007bff; 
  }
  .input-group-text {
  width: 48px;
  border-right: none !important;
  background-color: #ffffff;
  }
  #email, #password {
    border-left: none; 
  }
  #logoBox{
    display: flex; 
    align-content: center;
    align-items: center;
    padding-bottom: 20px; 
  }
  #logotext {
    font-size: 30px;
  }
</style>