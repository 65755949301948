<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        
      </div>
    </nav>
  </div>
</template>
<script>
import { auth } from "../firebase"
import { signOut } from "firebase/auth";

export default {
  name: 'Navbar',
  components: {
  },
  methods: {
    signOutNow() {
      signOut(auth)
      .then(async () => {
        await this.$store.dispatch("fetchUser", null)
        this.$router.replace( {path:"/login"});
      });
    }
  }
};
</script>
<style>
.navbar { 
  background-color:#4089C9 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -17px;
}
.navbar a.nav-link,
.navbar li a {
  cursor: pointer;
  color:rgb(255, 255, 255) !important;
}
.navbar a.nav-link:hover,
.navbar li a:hover {
  color:rgb(225, 225, 225)!important;
}
.navbar ul li ul li a {
  color: #3d3d3d !important;
}
.navbar ul li ul li a:hover {
    color: #676767 !important;
}
.navbar ul li ul li a:active {
    color: #fefefe !important;
}
</style>