<template>
  <div id="import-users">
    <b-container>
      <b-row class="m-1">
        <b-col cols="6">
          <div class="input-group mb-3" v-if="!loading && !done">
            <b-form-group label="Excel Import" label-for="form-file" >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="list"></b-icon>
                </b-input-group-prepend>
                <b-form-file id="form-file" accept=".xlsx" @change="loadData"></b-form-file>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6" v-if="!loading && !done">
          <div class="d-flex align-items-center h-100">
            <b-link href="../Beispiel_Import_F2F.xlsx">Beispiel Datei</b-link>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="" v-if="loading && !done">
            <b-progress :value="loading" max="100" show-progress animated></b-progress>
          </div>
          <div class="alert alert-success" v-if="done">
            <p>Upload Abgeschlossen</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { read, utils } from 'xlsx'
import { firestore } from "../firebase";
import { doc, setDoc} from "firebase/firestore";
import store from "../store"

export default {
  name: 'ImportUsers',
  data(){
    return{
      done: false,
      progress: true,
      fileReader: new FileReader(),
      convertedUpload: false,
      loading: 0
    }
  },
  methods:{
    returnData(x){
      return new Promise((resolve, reject) => {
        this.fileReader.onload = function(e) { 
          const rawData = resolve( read(e.target.result, {type: 'binary'}))
          reject(rawData)
        }
        this.fileReader.readAsBinaryString(x)
      })
    },
    async uploadData(data){
      const length = data.length
      let thisId = 0
      for await (let row of data){
        const id = row['email'].toLowerCase().replace(/ /g, "")
        const dataToSave = {
          name: row['name'],
          email: row['email'].toLowerCase().replace(/ /g, ""),
          company: row['company'],
          group: row['group']
        }
        await setDoc(doc(firestore, "users", id), dataToSave);
        thisId++
        this.loading = (thisId*100/length).toFixed(0)
      }
    },
    async loadData(data){
      this.progress = true
      const raw = await this.returnData(data.target.files[0])
      this.convertedUpload = await utils.sheet_to_json(raw.Sheets[Object.keys(raw.Sheets)[0]], {defval:""})
      await this.uploadData(this.convertedUpload)
      this.progress = false
      this.done = true
      store.dispatch('fetchUsers')
      return 
    }
  }
}
</script>

<style scoped>
 
</style>
