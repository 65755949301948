<template>
  <div id="events">
    <b-col>
      <b-alert show variant="warning text-center">
        <b-form-checkbox switch v-model="checkDoubled" size="xl" @click="checkDoubled = !checkDoubled">Prüfung doppelter Terminvergabe</b-form-checkbox>
        <p>Achtung! Könnte die Prozesse verlangsamen!</p>
      </b-alert>
      <div v-if="rooms" class="m-5">
        <b-row>
          <b-col cols="2"> 
            <div class="sticky-top">
              <h4>Räume</h4>
              <b-list-group>
                <b-list-group-item class="clickable"  v-for="(room, index) in rooms" :key="index" @click="selectedRoomIndex = index" :class="selectedRoomIndex===index && 'selected'">{{room}}</b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="2 sticky-top ownScroll">
            <div>
              <h4>Zeiten</h4>
              <b-list-group>
                <b-list-group-item class="clickable" v-for="(time, index) in times" :key="time.name + index" @click="selectedTimeIndex=index" :class="selectedTimeIndex===index && 'selected'"> <span style="width: 20px!important; position: absolute; right: 0px; "><font-awesome-icon :icon="['fas', 'circle']" v-if="meetings?.[`${selectedRoomIndex}-${index}`]?.members?.length > 0"/> </span> {{time.name}}</b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="5" >
            <div class="sticky-top">
              <h4>TERMIN</h4>
                <b-alert
                  :show="success"
                  variant="success"
                  @dismiss-count-down="countDownChanged"
                >
                  {{successText}}
                </b-alert>
              <b-list-group>
                <b-list-group-item>
                  <b>{{rooms[selectedRoomIndex]}}</b>
                  <br/>
                  <b>{{times[selectedTimeIndex].name}} Uhr</b>
                  <br/>
                  <b-input-group>
                    <b-form-input placeholder="Titel" v-model="meetingTitle" class="pb-3 pt-3"></b-form-input>
                    <b-input-group-append v-show="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.titel !==  meetingTitle">
                      <b-button variant="outline-success" @click="saveTitle()" >Speichern</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group class="mt-1">
                    <b-form-input placeholder="Informations Text" v-model="meetingSubTitle" class="pb-3 pt-3"></b-form-input>
                    <b-input-group-append v-show="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.subTitle !==  meetingSubTitle">
                      <b-button variant="outline-success" @click="saveTitle()" >Speichern</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <br>
                  <b-form-checkbox switch v-model="meetingShowMember" size="xl" @change="saveTitle()">Teilnehmer <span v-show="!meetingShowMember">nicht</span> anzeigen</b-form-checkbox>
                  <hr/>
                  <p>Teilnehmer</p>
                  <div class="d-flex justify-content-between mb-3">
                    <b-button size="sm" variant="primary" style="height: 22px; padding-top: 0;" v-if="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.members.length < users.length || !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]" @click="setUser('Hersteller')">Hersteller</b-button>
                    <b-button size="sm" variant="primary" style="height: 22px; padding-top: 0;" v-if="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.members.length < users.length || !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]" @click="setUser('Mittwoch')">Mittwoch</b-button>
                    <b-button size="sm" variant="primary" style="height: 22px; padding-top: 0;" v-if="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.members.length < users.length || !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]" @click="setUser('Donnerstag')">Donnerstag</b-button>
                    <b-button size="sm" variant="primary" style="height: 22px; padding-top: 0;" v-if="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.members.length < users.length || !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]" @click="setAll()">Alle Teilnehmer</b-button>
                    <b-button size="sm" variant="danger" style="height: 22px; padding-top: 0;" v-else @click="deleteAll()">Alle Teilnehmer entfernen</b-button>
                  </div>
                  <div class="d-flex align-items-center justify-content-center h-100 dropArea" @drop='onDrop($event)' @dragover.prevent @dragenter.prevent>
                    <div class="plus"><font-awesome-icon :icon="['fas', 'plus']"/></div>
                  </div>
                  <b-list-group v-if="meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]?.members">
                    <b-list-group-item v-for="(member, index) in meetings[`${selectedRoomIndex}-${selectedTimeIndex}`].members" :key="member + index" :class="isDoubled(times[selectedTimeIndex].time, member) && 'doubled'" >
                      <div class="d-flex justify-content-between ">
                        <span>
                          <span class="triangle"><b-badge variant="dark"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" style="color: white;" @click="showDeleteModal(member)"/> PERSON IN MEHREREN TERMINEN</b-badge><br></span>
                          {{users.find(e=>e.email === member)?.name}}<br>
                          {{users.find(e=>e.email === member)?.company}}<br>
                          {{member}}
                          <b-badge variant="dark" class="m-3" style="position: absolute; right: 0px; bottom: 0px;">{{users.find(e=>e.email === member)?.group}}</b-badge>
                        </span>
                        <font-awesome-icon :icon="['fas', 'trash']" @click="showDeleteModal(member)" />
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col >
            <div>
              <h4>Teilnehmer</h4>
              <b-input-group>
                <b-form-input placeholder="Suchen.." v-model="searchInput" class="mb-3"></b-form-input>
              </b-input-group>
              <b-input-group >
                <b-form-select v-model="selectedFilter" :options="options" class="mb-3"></b-form-select>
              </b-input-group>
              <b-list-group>
                <b-list-group-item  v-for="(element) in users" :key="element.id" draggable @dragstart="startDrag($event, element, 'users')" v-show="searchFilter(element)">
                  <div class="dragArea d-flex justify-content-between align-items-center bg-white">
                    <div>{{element.name}}<br>{{element.company}}<br>{{element.email}}</div>
                    <b-badge variant="dark" class="m-3" style="position: absolute; right: 0px; bottom: 0px;">{{element.group}}</b-badge>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-modal id="modal" ref="modal" title="Teilnehmer Löschen" @ok="deleteThis()" ok-title="Löschen" cancel-title="Abbrechen" cancel-variant="danger">
      <p class="my-4">Dieser Teilnehmer wird bei bestätigung aus diesem Meeting gelöscht.</p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { firestore } from "../firebase"
import { setDoc, doc, updateDoc } from "firebase/firestore"
import store from '../store'

export default {
  name: 'Users',
  computed: {
    ...mapGetters({
      users: "users",
      meetings: "meetings"
    }),
  },
  watch: {
    selectedRoomIndex () {
      this.meetingTitle = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.titel ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.titel : ""
      this.meetingSubTitle = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.subTitle ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.subTitle : ""
      this.meetingShowMember = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.meetingShowMember != undefined ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.meetingShowMember : true
    },
    selectedTimeIndex () {
      this.meetingTitle = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.titel ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.titel : ""
      this.meetingSubTitle = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.subTitle ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.subTitle : ""
      this.meetingShowMember = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.meetingShowMember != undefined ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.meetingShowMember : true
    }
  },
  data(){
    return {
      selectedFilter: null, 
      options: [
        { value: null, text: 'Kein Filter' },
        { value: 'Hersteller', text: 'Hersteller' },
        { value: 'Mittwoch', text: 'Mittwoch' },
        { value: 'Donnerstag', text: 'Donnerstag' }
      ],
      checkDoubled: false, 
      meetingTitle: "",
      meetingSubTitle: "",
      meetingShowMember: true ,
      membertoDelete: null,
      searchInput: "",
      selectedRoomIndex: 0,
      selectedTimeIndex: 0,
      dropData:  null,
      success: 0,
      successText: 'OK',
      error: false,
      times:[
          {showtime: '10:00 - 16:00', name: 'Mi. 10:00 - 16:00', time: new Date(Date.UTC(2022, 8, 28, 8, 0, 0)) },
          {showtime: '13:00 - 14:00', name: 'Mi. 13:00 - 14:00', time: new Date(Date.UTC(2022, 8, 28, 11, 0, 0)) },
          {showtime: 'ab 16:00', name: 'Mi. ab 16:00', time: new Date(Date.UTC(2022, 8, 28, 14, 0, 0)) },
          {showtime: '18:00 - 18:30', name: 'Mi. 18:00 - 18:30', time: new Date(Date.UTC(2022, 8, 28, 16, 0, 0)) },
          {showtime: '18:30 - 22:00', name: 'Mi. 18:30 - 22:00', time: new Date(Date.UTC(2022, 8, 28, 16, 30, 0)) },
          {showtime: '18:30 - 0:00',  name: 'Mi. 18:30 - 0:00', time: new Date(Date.UTC(2022, 8, 28, 16, 30, 1)) },
          {showtime: '7:00 - 10:00',  name: 'Do. 7:00 - 10:00', time: new Date(Date.UTC(2022, 8, 29, 5, 0, 0)) }, 
          {showtime: '10:00 - 10:25', name: 'Do. 10:00 - 10:25', time: new Date(Date.UTC(2022, 8, 29, 8, 0, 0)) }, 
          {showtime: '10:30 - 10:55', name: 'Do. 10:30 - 10:55', time: new Date(Date.UTC(2022, 8, 29, 8, 30, 0)) },
          {showtime: '11:00 - 11:25', name: 'Do. 11:00 - 11:25', time: new Date(Date.UTC(2022, 8, 29, 9, 0, 0)) },
          {showtime: '11:30 - 11:55', name: 'Do. 11:30 - 11:55', time: new Date(Date.UTC(2022, 8, 29, 9, 30, 0)) },
          {showtime: '12:00 - 13:30', name: 'Do. 12:00 - 13:30', time: new Date(Date.UTC(2022, 8, 29, 10, 0, 0)) },
          {showtime: '13:30 - 14:00', name: 'Do. 13:30 - 14:00', time: new Date(Date.UTC(2022, 8, 29, 11, 30, 0)) },
          {showtime: '14:00 - 14:30', name: 'Do. 14:00 - 14:30', time: new Date(Date.UTC(2022, 8, 29, 12, 0, 0)) },
          {showtime: '14:30 - 14:45', name: 'Do. 14:30 - 14:45', time: new Date(Date.UTC(2022, 8, 29, 12, 30, 0)) },
          {showtime: '14:45 - 15:10', name: 'Do. 14:45 - 15:10', time: new Date(Date.UTC(2022, 8, 29, 12, 45, 0)) },
          {showtime: '15:15 - 15:40', name: 'Do. 15:15 - 15:40', time: new Date(Date.UTC(2022, 8, 29, 13, 15, 0)) },
          {showtime: '15:45 - 16:10', name: 'Do. 15:45 - 16:10', time: new Date(Date.UTC(2022, 8, 29, 13, 45, 0)) },
          {showtime: '16:15 - 16:40', name: 'Do. 16:15 - 16:40', time: new Date(Date.UTC(2022, 8, 29, 14, 15, 0)) },
          {showtime: '16:45 - 17:10', name: 'Do. 16:45 - 17:10', time: new Date(Date.UTC(2022, 8, 29, 14, 45, 0)) },
          {showtime: '17:15 - 17:40', name: 'Do. 17:15 - 17:40', time: new Date(Date.UTC(2022, 8, 29, 15, 15, 0)) },
          {showtime: '17:45 - 18:10', name: 'Do. 17:45 - 18:10', time: new Date(Date.UTC(2022, 8, 29, 15, 45, 0)) },
          {showtime: '18:15 - 18:40', name: 'Do. 18:15 - 18:40', time: new Date(Date.UTC(2022, 8, 29, 16, 15, 0)) },
          {showtime: '19:00 - 19:15', name: 'Do. 19:00 - 19:15', time: new Date(Date.UTC(2022, 8, 29, 17, 0, 0)) },
          {showtime: '19:30 - 21:30', name: 'Do. 19:30 - 21:30', time: new Date(Date.UTC(2022, 8, 29, 17, 30, 0)) },
          {showtime: '21:30 - 00:00', name: 'Do. 21:30 - 00:00', time: new Date(Date.UTC(2022, 8, 29, 19, 30, 0)) },
          {showtime: '7:00 - 9:00', name: 'Fr. 7:00 - 9:00', time: new Date(Date.UTC(2022, 8, 30, 5, 0, 0)) },
          {showtime: '9:00 - 9:45', name: 'Fr. 9:00 - 9:45', time: new Date(Date.UTC(2022, 8, 30, 7, 0, 0)) },
          {showtime: '10:00 - 10:45', name: 'Fr. 10:00 - 10:45', time: new Date(Date.UTC(2022, 8, 30, 8, 0, 0)) },
          {showtime: 'bis 11:00', name: 'Fr. bis 11:00', time: new Date(Date.UTC(2022, 8, 30, 9, 0, 0)) },
          {showtime: 'ab 11:00', name: 'Fr. ab 11:00', time: new Date(Date.UTC(2022, 8, 30, 9, 0, 1)) },
          {showtime: '20:00 - 21:30', name: 'Mi. 20:00 - 21:30', time: new Date(Date.UTC(2022, 8, 28, 18, 0, 0)) },
          {showtime: '21:30 - 0:00',  name: 'Mi. 21:30 - 0:00', time: new Date(Date.UTC(2022, 8, 28, 19, 30, 0)) },
          {showtime: '21:00 - 22:00', name: 'Mi. 21:00 - 22:00', time: new Date(Date.UTC(2022, 8, 28, 19, 0, 0)) },
      ],
      rooms: [
        'Hotel',
        'BeachBay 1',
        'BeachBay 2',
        'BeachBay 3',
        'BeachBay 4',
        'BeachBay 5',
        'BeachBay 6',
        'BeachBay 7',
        'Suite 1',
        'Suite 2',
        'Suite 3',
        'Suite 4',
        'Suite 5',
        'Suite 6',
        'Suite 7',
        'Suite 8',
        'Suite 9',
        'Suite 10'
      ]
    }
  },
  methods:{
    searchFilter(element){
      let show = true
  
      if(Array.isArray(this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.members) && 
        this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.members.includes(element.email)
        ){show = false}

      if( this.selectedFilter !== null && 
          element.group.toUpperCase().search(this.selectedFilter.toUpperCase()) < 0
          ||
          this.searchInput !== "" && 
          element.email.toUpperCase().search(this.searchInput.toUpperCase()) < 0 && 
          element.name.toUpperCase().search(this.searchInput.toUpperCase()) < 0 && 
          element.company.toUpperCase().search(this.searchInput.toUpperCase()) < 0
        ){show = false}
      return show
    },
    selectedtRoomId(id){
      alert(id)
      return id
    },
    startDrag (evt, element) {
      this.dropData = element
    },
    onDrop () {
      let data = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] : {members: []}
      data.members = Array.isArray(data?.members) ? data.members : []
      data.members =  [...new Set([...data.members ,...[this.dropData.email]])]
      data.titel = this.meetingTitle
      data.subTitle = this.meetingSubTitle
      data.room = this.rooms[this.selectedRoomIndex]
      data.time = this.times[this.selectedTimeIndex].time
      data.timeString = this.times[this.selectedTimeIndex].name
      data.showtime = this.times[this.selectedTimeIndex].showtime
      data.meetingShowMember = this.meetingShowMember

      setDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), data)
      store.dispatch('fetchMeetings')
    },
    deleteAll(){
      updateDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), {members: []})
      store.dispatch('fetchMeetings')
    },
    countDownChanged(success) {
      this.success = success
    },
    setAll(){
      const emails = this.users.map(function (obj) {
        return obj.email;
      });
      if (this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]){
        updateDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), {members: emails})
        .then(()=>{
          this.showSuccess(`Alle Teilnehmer wurden hinzugefügt.`)
        })
      } else {
        setDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), {members: emails})
        .then(()=>{
          this.showSuccess(`Alle Teilnehmer wurden hinzugefügt.`)
        })
      }
      store.dispatch('fetchMeetings')
    },
    setUser(flag){
      const emailsFiltered = this.users.filter(function (obj) {
        if (obj.group === flag) return obj.email
      });

      let emails = emailsFiltered.map(function (obj) {
        return obj.email;
      });

      if (Array.isArray(this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]?.members)){
        emails = [...new Set([...emails ,...this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`].members])]
      }

      if (this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]){
        updateDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), {members: emails})
        .then(()=>{
          this.showSuccess(`Der Teilnehmer wurde hinzugefügt.`)
        })
      } else {
        setDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), {members: emails})
        .then(()=>{
          this.showSuccess(`Der Teilnehmer wurde hinzugefügt.`)
        })
      }

      store.dispatch('fetchMeetings')
    },
    showSuccess(text){
      this.success = 1.5
      this.successText = text
    },
    showDeleteModal(member){
      this.membertoDelete = member
      this.$refs['modal'].show()
    },
    deleteThis(){
      const membertoDelete = this.membertoDelete
      let data = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] : {members: []}
      data.members = data.members.filter(function(item) {
        return item !== membertoDelete
      })
      setDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), data)
    },
    saveTitle(){
      let data = this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`] : {members: []}
      data.titel = this.meetingTitle
      data.subTitle = this.meetingSubTitle
      data.room = this.rooms[this.selectedRoomIndex]
      data.time = this.times[this.selectedTimeIndex].time
      data.showtime = this.times[this.selectedTimeIndex].showtime
      data.timeString = this.times[this.selectedTimeIndex].name
      data.meetingShowMember = this.meetingShowMember

      setDoc(doc(firestore, 'meetings', `${this.selectedRoomIndex}-${this.selectedTimeIndex}`), data)
      store.dispatch('fetchMeetings')
    },
    isDoubled(time, email){ 
      if (!this.checkDoubled){
      return false
      } else {
      //TODO: Wenn möglich schneller machen.
      let meetingsArray = Object.entries(this.meetings)
      meetingsArray = meetingsArray.filter(element => 
        element[1]?.time && 
        element[1]?.time?.toDate().toLocaleString('de-DE') === time?.toLocaleString('de-DE') &&
        element[1].members.includes(email)
      )
      return meetingsArray.length > 1 ? true : false
      }
    }
  },
}
</script>

<style scoped>
  #admin {
    padding-top: 30px;
  }
  body, div {
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
  }
  .dragable_logo {
    max-width: 50px;
    max-height: 50px;
  }
  small {
    font-size: 10px;
    color: lightgray;
  }
  .bg-white {
    background-color: #ffffff;
  }
  .dropArea {
    border: 1px #6f6f6f dashed;
    border-radius: 5px;
    width: 100%;
    min-height: 90px;
    margin: 3px;
    margin-left: 0;
    padding: 3px;
  }
  .plus {
    color: #aeaeae;
  }
  .dropArea.done{
    border: 1px solid;
    border-color: green;
  }
  .dropArea.empty{
    border-color: orange;
  }
  .dragArea{
    cursor: grab;
  }
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    background-color: #ececec;
  }
  .doubled{
    background-color: rgb(255, 221, 221);
  }
  span.triangle {
    display: none;
  }
  .doubled span.triangle {
    display: block;
  }
  .selected, .selected:hover {
    background-color: #4089C9;
    color: white;
  }
  .inUse, .inUse:hover {
    background-color: #a1a1a1;
    color: rgb(0, 0, 0);
  }
  .ownScroll {
    height: calc(100vh - 68px);
    overflow-x: scroll;
  }
</style>
